export const colors = {
    LandingPage_Front : '#F1F2F1',
    LandingPage_Background: '#E9E9E1',
    LandingPage_Description: '#000000',
    Button_Primary: '#58D5D3',
    Button_Secondary: '#58D5D3', 
    Header_Background: "#FFFFFF",
    Header_Content: '#000000',
    AppMenu_Header: '#000000',
    ResponsiveDrawer_Header: '#000000',
}