export const FirebaseConfig = {
	"projectId": "vocalride-70223",
	"appId": "1:215140946720:web:b330bedd9e00235e27fd45",
	"databaseURL": "https://vocalride-70223-default-rtdb.firebaseio.com",
	"storageBucket": "vocalride-70223.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCqAtb3eN1Ve_rtAfUB50DuJo0ndMuJzT8",
	"authDomain": "vocalride-70223.firebaseapp.com",
	"messagingSenderId": "215140946720",
	"measurementId": "G-GL3BDD529D"
};